.quizForm-container {
  width: auto;
}

.quizFrom-button {
  font-family: "Nunito", sans-serif;
  background-color: #007c84;
  /* width: 8vw; */
  min-height: 4vh;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  color: white;
  margin-left: auto;
}

.quizFrom-mainFrom {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin-top: 1em;
}

.quizFrom-mainFrom-head {
  text-align: center;
  color: #00495f;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 1em;
}

.quizForm-mainForm-infoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em;
  font-weight: 500;
}
.quizForm-mainForm-label {
  width: 20vw;
}
.quizForm-mainForm-inputBar {
  width: 25vw;
  height: 2em;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}
.quizForm-mainForm-textarea {
  width: 25vw;
  height: 5em;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}
.quizForm-mainForm-select {
  width: 25vw;
  text-align: center;
  background-color: #ffffff;
  color: #717579;
  height: 2em;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}

.quizForm-sequence-label {
  margin: 1em;
}
.quizForm-fill-label {
  margin: 1em;
}
.quizForm-sequence-options {
  width: 100%;
  text-align: center;
  margin-top: 1em;
}

.quizForm-matchUp-inputBar {
  width: 25vw;
  height: 2em;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin: 1em;
}
.quizForm-matchUp-label {
  width: 10vw;
  display: inline;
}

.quizForm-matchUpPic-label {
  width: 20vw;
  margin-left: 1em;
}
.quizForm-matchUpPic-inputBar {
  width: 25vw;
  height: 2em;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin: 1em;
}

.quizForm-chooseImage-infoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5em;
  margin-bottom: 1em;
}
.quizForm-chooseImage-label {
  width: 10vw;
}
.quizForm-chooseImage-image {
  height: 100px;
  width: 150px;
  background-color: #d7d7d7;
  border-radius: 5px;
}
