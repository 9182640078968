.active {
    color: #fff;
    background: linear-gradient(to right, #007848, #004577);
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-radius: 10px;
    fill: #fff;
    margin-top: 20px;
}

.activeHide {
    color: #fff;
    background: linear-gradient(to right, #007848, #004577);
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-radius: 10px;
    fill: #fff;
    margin-top: 20px;
}
.inactive {
    color: #007C84;
    fill: #007C84;
    background: #00768714;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 20px;
}

/* .sidebarBottom {
    position: fixed;
    z-index: 999999;
    bottom: 55px;
    left: 0;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0px 15px;
}

.sidebarBottomHide {
    position: fixed;
    z-index: 999999;
    bottom: 55px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 0px 15px;
} */