.outer-bigger-container {
    /* border: 2px solid gray; */
    margin: 54px;
  }
  
  .setting-table-main {
    border: 1px solid #1e3758;
    border-radius: 10px;
    background-color: #fff;
    padding: 5px 20px;
  }
  
  .bigger-container {
    color: #2c343e;
    font-weight: 500;
    padding: 10px 15px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    font-family: "Poppins", sans-serif;
  }
  
  .outer-inner-container {
    display: flex;
    border-top: 1px solid lightgray;
    justify-content: space-between;
    align-items: center;
  }
  
  .templatename {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    padding: 14px;
  }
  
  .button-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
  }
  
  .button-box-inner {
    margin: 5px 10px;
  }
  .create-new-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .setting_details_table {
    margin-bottom: 100px;
  }
  