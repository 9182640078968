.Navbar{
    width:250px; 
    height: 100%;
    padding-top: 30px;
    padding-bottom: 55px;
    text-align: center;
    font-size: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: semi-bold;
    letter-spacing: 1px;
    background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  );
}