.createcourse-main {
  display: grid;
  grid-template-columns: 6vw 1fr;
  background-color: #e5e5e5;
}
.createcourse-main-bottom {
  /* margin-left: 6vw; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #e5e5e5;
  font-family: "Poppins", sans-serif;
  margin: 0 1em;
  margin-bottom: 3em;
}

.addLesson-btn {
  font-family: "Nunito", sans-serif;
  background-color: #007c84;
  width: 8vw;
  min-height: 4vh;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  color: white;
}

.lessonForm-container {
  width: 97%;
  padding: 1em;
  background-color: white;
  margin-top: 1em;
  border-radius: 3px;
}
.lessonForm-heading {
  color: #00495f;
  height: 2em;
  font-weight: 600;
  font-size: 25px;
  display: grid;
  align-items: center;
  justify-content: left;
}

.lessonForm-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  align-items: center;
}

.lessonForm-inputLabel {
  width: 20vw;
}
.lessonForm-inputBar {
  width: 20vw;
  height: 4vh;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}
.lessonForm-inputFile {
  width: 20vw;
  height: 4vh;
}
.lessonForm-save-btn {
  font-family: "Nunito", sans-serif;
  background-color: #007c84;
  width: 8vw;
  min-height: 4vh;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  color: white;
}

.lessonList-container {
  width: 97%;
  padding: 1em;
  background-color: white;
  margin-top: 1em;
  border-radius: 3px;
}

.lessonList-draggable {
  margin-top: 10px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}

.lessonList-draggable-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lessonList-accordion-heading {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: large;
}
